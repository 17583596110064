import dynamic from 'next/dynamic'
import Image from 'next/image'

const SelectByCountry = dynamic(() => import('../dropdowns/selectByCountry'))


export default function HighlightsHomeComponent() {
    return (
        <article className='sectionOrArticleTB' >
            <div className='text-center'>
                <h2 className='caregoriesTitle'>Inventory Highlights</h2>
            </div>
            <div className='imgStripContainer'>
                <div className='imgStripText'>
                    <Image src='/images/Strip1.gif'
                        alt="Strip"
                        fill
                    />
                </div>
            </div>
            <SelectByCountry />
        </article>
    )
}